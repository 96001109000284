import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import BannerWithForm from "../../components/openday/implantologia/bannerWithForm"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import BannerWithTextAndForm from "../../components/reablitacao-estetica/BannerWithForm"
import ContainerSmile from "../../components/reablitacao-estetica/ContainerSmile"
import OptionServices from "../../components/reablitacao-estetica/OptionsServices"
import CTA from "../../components/reablitacao-estetica/CTA"
import ContextText from "../../components/reablitacao-estetica/ContextText"
import Main from "../../components/reablitacao-estetica/Main"
import VantagesSmile from "../../components/reablitacao-estetica/VantagesSmile"
import BoxSlides from "../../components/reablitacao-estetica/boxSlides"
import FAQAccordion from "../../components/reablitacao-estetica/FAQAccordion"
import BannerMobile from "../../components/reablitacao-estetica/BannerMobile"
import Context from "../../components/reablitacao-estetica/Context"
import FAQAccordionMobile from "../../components/reablitacao-estetica/FAQAccordionMobile"
import BoxSlidesMobile from "../../components/reablitacao-estetica/boxSlidesMobile"
import Form from "../../components/reablitacao-estetica/Form"
import VantagesSmileMobile from "../../components/reablitacao-estetica/VantagesSmileMobile"
import ContextTextMobile from "../../components/reablitacao-estetica/ContextTextMobile"
import CTAMobile from "../../components/reablitacao-estetica/CTAMobile"
import NavbarReabilitacao from "../../components/reablitacao-estetica/navbarMobileReabilitacao"

const CoroasPage = ({ data }) => {
  return (
    <>
      <SEO
        titleTemplate="Reabilitação Estética - Coroas"
        title="Carreira Dental Clinic"
        //noindex //ISSO É PRA NAO INDEXAR NO GOOGLE, SE VOLTAR A POR O OPENDAY ISSO NAO PODE ESTAR
      />
      <Layout home mobile={useBreakpoint().mobile}>
        {useBreakpoint().mobile ? (
          <>
            <NavbarReabilitacao />
            <BannerMobile
              data={data.reabilitacaoEsteticaJson.coroas}
              TextColor="#fff"
              flexPosition="flex-end"
              noHome
            />
            <Context data={data.reabilitacaoEsteticaJson.coroas} withTitle />

            <ContextTextMobile
              data={data.reabilitacaoEsteticaJson.coroas.contextText}
            />
            <Main data={data.reabilitacaoEsteticaJson.coroas.main} />
            <CTAMobile />
            <VantagesSmileMobile
              data={data.reabilitacaoEsteticaJson.coroas.vantagesSmile}
            />
            <BoxSlidesMobile
              data={data.reabilitacaoEsteticaJson.coroas.boxSlide}
            />
            <Form
              data={data.reabilitacaoEsteticaJson.coroas}
              pageName="Coroas"
            />
            <FAQAccordionMobile data={data.reabilitacaoEsteticaJson.coroas} />
            <FooterMobile data={data.globalJson.footer} />
          </>
        ) : (
          <>
            <Navbar />
            <BannerWithTextAndForm
              data={data.reabilitacaoEsteticaJson.coroas}
              TextColor="#000"
              pageName="Coroas"
            />
            <ContextText
              data={data.reabilitacaoEsteticaJson.coroas.contextText}
            />
            <Main data={data.reabilitacaoEsteticaJson.coroas.main} />
            <CTA />
            <VantagesSmile
              data={data.reabilitacaoEsteticaJson.coroas.vantagesSmile}
            />
            <BoxSlides data={data.reabilitacaoEsteticaJson.coroas.boxSlide} />
            <FAQAccordion data={data.reabilitacaoEsteticaJson.coroas} />
            <Footer data={data.globalJson.footer} footerTop />
          </>
        )}
      </Layout>
    </>
  )
}

export default CoroasPage

export const Json = graphql`
  query coroas {
    reabilitacaoEsteticaJson {
      coroas {
        banner {
          title
          subtitle
          banner {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bannerMobile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        container {
          title
          txt
        }
        main
        contextText {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          right {
            text
            title
          }
        }
        vantagesSmile {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          text {
            text
            title
          }
        }
        boxSlide {
          imgBefore {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgAfter {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        faq {
          question
          answer
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
