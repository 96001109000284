import React, { useState, useRef, useEffect } from "react"
import useEventListener from "../../custom/use-event-listener"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import Div100vh from "react-div-100vh"
import { Button } from "../buttons"
import useLockBodyScroll from "../../custom/use-lock-body-scroll"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

import logoB from "../../images/logo_preto.svg"
import logoW from "../../images/logo_branco.svg"
import hamb from "../../images/hamb.svg"
import hambB from "../../images/hamb-brown.svg"
import cross from "../../images/cross.svg"
import face from "../../images/toolbar/facebook.svg"
import insta from "../../images/toolbar/instagram.svg"
import tube from "../../images/toolbar/youtube.svg"

import seloB from "../../images/selo-black.png"
import seloW from "../../images/selo-white.png"

import { handleClick } from "../../custom/gtag-click"

const Navbar = ({ color }) => {
  const navbar = useRef(null)
  const [prevScrollPos, setPrevScrollPos] = useState("")
  const [currentScrollPos, setCurrentScrollPos] = useState("")
  const [screenVH, setScreenVH] = useState("")
  const [isHome, setIsHome] = useState(color === "white" ? true : false)

  useEffect(() => {
    setPrevScrollPos(window.pageYOffset)
    setScreenVH(window.innerHeight)
  }, [])
  useEventListener("scroll", () => scroller())

  function scroller() {
    setCurrentScrollPos(window.pageYOffset)
    if (prevScrollPos > currentScrollPos || window.pageYOffset <= 0) {
      navbar.current.style.top = "0px"
      if (currentScrollPos > screenVH && color === "white") {
        setIsHome(false)
      } else if (color === "white") {
        setIsHome(true)
      }
    } else {
      navbar.current.style.top = "-15vh"
    }
    setPrevScrollPos(currentScrollPos)
  }

  const [open, setOpen] = useState(false)
  const [lock, setLock] = useState(false)

  useLockBodyScroll(lock)

  return (
    <StyledNav isHome={isHome} ref={navbar}>
      {isHome ? (
        <Button
          styleless
          className="logonav"
          onClick={() => {
            setOpen(true)
            setLock(true)
          }}
        >
          <Link className="logo" to="/">
            <img src={logoW} alt="Logo" className="logoCarreira" />
            <img src={seloW} alt="Selo de 20 anos" className="selo" />
          </Link>
          <img src={hamb} className="menu" alt="Menu" />
        </Button>
      ) : (
        <Button
          styleless
          className="logonav"
          onClick={() => {
            setOpen(true)
            setLock(true)
          }}
        >
          <Link className="logo" to="/">
            <img src={logoB} alt="Logo" className="logoCarreira bigger" />
            <img src={seloB} alt="Selo de 20 anos" className="selo bigger" />
          </Link>
          <img src={hambB} className="menu" alt="Menu" />
        </Button>
      )}
      <CSSTransition in={open} timeout={350} classNames={"open"} unmountOnExit>
        <StyledDrawer>
          <Button
            noHover
            styleless
            className="logonavDrawer"
            onClick={() => {
              setOpen(false)
              setLock(false)
            }}
          >
            <Link to="/" className="linkLogo">
              <div className="logo" to="/">
                <img src={logoB} alt="Logo" className="logoCarreira" />
              </div>
            </Link>
            <img src={cross} className="menu" alt="Fechar Menu" />
          </Button>
          <div className="daRest">
            <div className="links">
              <Link
                to="/"
                className={font.rSB + " link"}
                activeClassName="active"
              >
                INÍCIO
              </Link>
              <Link
                to="/sobre"
                className={font.rSB + " link"}
                activeClassName="active"
              >
                SOBRE
              </Link>
              <Link
                to="/servicos"
                className={font.rSB + " link"}
                activeClassName="active"
                partiallyActive={true}
              >
                SERVIÇOS
              </Link>
              <Link
                to="/sorrisos"
                className={font.rSB + " link"}
                activeClassName="active"
              >
                SORRISOS
              </Link>
              <Link
                to="/blogue"
                className={font.rSB + " link"}
                activeClassName="active"
                partiallyActive={true}
              >
                BLOGUE
              </Link>
              <Link
                to="/contactos"
                className={font.rSB + " link"}
                activeClassName="active"
              >
                CONTACTOS
              </Link>
              <div className="social">
                <a
                  href="https://www.facebook.com/carreiradentalclinic"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={face} alt="Logo do Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/carreiradentalclinic/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={insta} alt="Logo do Instagram" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEtWAWV_Vvhu7jvuDQ081sQ"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={tube} alt="Logo do Youtube" />
                </a>
              </div>
            </div>
            <Link
              to="/pedido-de-marcacao"
              className={font.rSB + " consulta"}
              activeClassName="consultaActive"
              id="consultaBtn"
            >
              MARCAR CONSULTA
            </Link>

            {/* <Link
              to="/openday/implantologia"
              className={font.rSB + " openday"}
              activeClassName="opendayActive"
              id="opendayBtn"
            >
              MÊS DA IMPLANTOLOGIA
            </Link> */}

            <Link
              to="/reabilitacao-estetica"
              className={font.rSB + " reabilitacao"}
              activeClassName="reabilitacaoActive"
              id="reabilitacaoBtn"
            >
              Reabilitação e Estética Dentária
            </Link>

            <div className="bottomContent">
              <div className="contacto">
                <p className={font.rB + " contTitle"}>
                  Clínica Dentária de Aveiro
                </p>
                <p className={font.rSB + " contTxt"}>
                  <a onClick={() => handleClick("tel:+351967310970", "Aveiro")}>
                    967 310 970<small style={{ fontSize: "12px" }}>·¹</small>
                  </a>
                  &nbsp; - &nbsp;
                  <a onClick={() => handleClick("tel:+351234423042", "Aveiro")}>
                    234 423 042<small style={{ fontSize: "12px" }}>·²</small>
                  </a>
                </p>
                <div style={{ marginTop: "9px" }}>
                  <small style={{ color: "darkgray" }}>
                    ·¹«Chamada para a rede móvel nacional»
                  </small>
                  <br></br>
                  <small style={{ color: "darkgray" }}>
                    ·²«Chamada para a rede fixa nacional»
                  </small>
                </div>
              </div>
              <div className="disc">
                <p className={font.rSB}>made by invisual.pt</p>
                <p className={font.rSB}>2020 © Carreira Dental Clinic</p>
              </div>
            </div>
          </div>
        </StyledDrawer>
      </CSSTransition>
    </StyledNav>
  )
}

export default Navbar

const StyledNav = styled.div`
  position: ${props => (props.isHome ? "absolute" : "relative")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  color: ${props => (props.isHome ? "#ffffff" : "#231f20")};
  background-color: ${props => (props.isHome ? "unset" : "#fff")};
  z-index: 2;
  box-shadow: ${props =>
    props.isHome ? "none" : "0 3px 6px 0 rgba(0, 0, 0, 0.16)"};
  a {
    cursor: pointer;
  }
  .logonav {
    padding: 10px 0;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .logo {
    top: 0;
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .linkLogo {
    width: fit-content;
    margin: auto;
  }
  .logoCarreira {
    margin: 0;
    position: relative;
    width: calc(120px + 80 * (100vw - 320px) / 448);
    text-align: center;
  }
  .selo {
    width: 6em;
    margin: 0 4vw;
  }
  .menu {
    width: 20px;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  .open-enter {
    position: fixed;
    top: -100vh !important;
  }
  .open-enter-active {
    position: fixed;
    top: 0% !important;
    transition: all 350ms ease-out;
  }
  .open-exit {
    position: fixed;
    top: 0% !important;
  }
  .open-exit-active {
    position: fixed;
    top: -100vh !important;
    transition: all 350ms ease-out;
  }
`

const StyledDrawer = styled(Div100vh)`
  background-color: #fff;
  color: #231f20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  .logonavDrawer {
    padding: 5% 0;
    position: relative;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .daRest {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 5vw;
  }
  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .link {
      position: relative;
      font-size: calc(14px + 8 * (100vw - 320px) / 448);
      letter-spacing: 0.1em;
      margin-bottom: 2em;
    }
    .active::after {
      content: "";
      background-color: #b7a99a;
      width: 25px;
      height: 3px;
      position: absolute;
      top: 1.6em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .social {
    margin: 0em auto;
    width: 50%;
    display: flex;
    justify-content: space-around;
    img {
      width: 40px;
    }
  }
  a {
    position: relative;
    text-align: center;
    transition: all 0.2s;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .openday {
    text-align: center;
    margin: 0em auto;
    width: 80%;
    height: 5em;
    line-height: 3em;
    border: solid 1px #b7a99a;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reabilitacao {
    text-align: center;
    margin: 0em auto;
    width: 80%;
    height: 3em;
    line-height: 3em;
    border: solid 1px #b7a99a;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .consulta {
    text-align: center;
    margin: 0em auto;
    margin-bottom: 1%;
    width: 80%;
    height: 3em;
    line-height: 3em;
    border: solid 1px #b7a99a;
  }
  .consultaActive {
    background-color: #eeeae6;
    letter-spacing: 0.2em;
    pointer-events: none;
  }

  .reabilitacaoActive {
    background-color: #eeeae6;
    letter-spacing: 0.2em;
    pointer-events: none;
  }
  .contacto {
    text-align: center;
    margin-bottom: 6%;
    .contTitle {
      color: #b7a99a;
      font-size: 14px;
      letter-spacing: 1.4px;
    }
    .contTxt {
      color: #707070;
      font-size: 18px;
      letter-spacing: 1.8px;
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  .disc {
    text-align: center;
    font-size: 14px;
    letter-spacing: 1.4px;
    color: #707070;
    margin-bottom: 5%;
  }
`
