import React from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

// Container principal que irá organizar os dois lados (esquerdo e direito)
const Container = styled.div`
  position: relative;

  .space {
    padding: 20vh 0;
  }
`

const TextLeft = styled.div`
  grid-column: 1 / 7;
  font-size: clamp(3.125rem, 2.0833rem + 1.3889vw, 3.75rem);
`
const TextRight = styled.div`
  grid-column: 8 / -1;
  font-size: clamp(1.375rem, 1.1667rem + 0.2778vw, 1.5rem);
`

const MainSide = ({ data }) => {
  return (
    <Container>
      <div className="space">
        <div className="grid-container">
          <TextLeft
            dangerouslySetInnerHTML={{
              __html: data.left,
            }}
            className={font.rB}
          />
          <TextRight
            dangerouslySetInnerHTML={{
              __html: data.right,
            }}
            className={font.rR}
          />
        </div>
      </div>
    </Container>
  )
}

export default MainSide
