import React from "react"
import styled from "styled-components"
import smile from "../../images/reabilitacao-estetica/banner-main-mobile.png"
import font from "../../fonts/fonts.module.scss"

// Container principal que irá organizar os dois lados (esquerdo e direito)
const Container = styled.div`
  position: relative;
  background: #adacac;
`

const RightBox = styled.div`
  position: relative;
  grid-column: 1 / -1;
`

// Estilo do subtítulo
const SubTitle = styled.p`
  color: #fff;
  text-align: right;
  font-size: clamp(2.1875rem, 2.0291rem + 0.6849vw, 2.5rem);
  padding-bottom: 50px;

  b {
    font-family: "rB", sans-serif;
  }
`

const ContainerSmileMobile = () => {
  return (
    <Container>
      <div className="grid-container">
        <RightBox>
          <SubTitle className={font.rR}>
            <b>
              Sorrisos
              <br /> bonitos e saudáveis
            </b>
            <br />
            estão, cada vez mais, <br />
            ao <b>alcance de todos!</b>
          </SubTitle>
        </RightBox>
      </div>
      <img src={smile} className="smile-man" />
    </Container>
  )
}

export default ContainerSmileMobile
