import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import BannerWithTextAndForm from "../../components/reablitacao-estetica/BannerWithForm"
import CTA from "../../components/reablitacao-estetica/CTA"
import Main from "../../components/reablitacao-estetica/Main"
import VantagesSmile from "../../components/reablitacao-estetica/VantagesSmile"
import MainSide from "../../components/reablitacao-estetica/MainSide"
import ContextText from "../../components/reablitacao-estetica/ContextText"
import BoxSlides from "../../components/reablitacao-estetica/boxSlides"
import FAQAccordion from "../../components/reablitacao-estetica/FAQAccordion"
import BannerMobile from "../../components/reablitacao-estetica/BannerMobile"
import Context from "../../components/reablitacao-estetica/Context"
import ContextTextMobile from "../../components/reablitacao-estetica/ContextTextMobile"
import CTAMobile from "../../components/reablitacao-estetica/CTAMobile"
import VantagesSmileMobile from "../../components/reablitacao-estetica/VantagesSmileMobile"
import BoxSlidesMobile from "../../components/reablitacao-estetica/boxSlidesMobile"
import Form from "../../components/reablitacao-estetica/Form"
import FAQAccordionMobile from "../../components/reablitacao-estetica/FAQAccordionMobile"
import NavbarReabilitacao from "../../components/reablitacao-estetica/navbarMobileReabilitacao"

const FacetasPage = ({ data }) => {
  return (
    <>
      <SEO
        titleTemplate="Reabilitação Estética - Facetas Dentárias"
        title="Carreira Dental Clinic"
        //noindex //ISSO É PRA NAO INDEXAR NO GOOGLE, SE VOLTAR A POR O OPENDAY ISSO NAO PODE ESTAR
      />
      <Layout home mobile={useBreakpoint().mobile}>
        {useBreakpoint().mobile ? (
          <>
            <NavbarReabilitacao />
            <BannerMobile
              data={data.reabilitacaoEsteticaJson.facetas}
              TextColor="#fff"
              flexPosition="flex-end"
              noHome
            />
            <Context data={data.reabilitacaoEsteticaJson.facetas} withTitle/>

            <ContextTextMobile
              facetas
              data={data.reabilitacaoEsteticaJson.facetas.contextText}
            />
            <CTAMobile />
            <VantagesSmileMobile
              data={data.reabilitacaoEsteticaJson.facetas.vantagesSmile}
            />
            <BoxSlidesMobile
              data={data.reabilitacaoEsteticaJson.facetas.boxSlide}
            />
            <Form
              data={data.reabilitacaoEsteticaJson.facetas}
              pageName="Facetas Dentárias"
            />
            <FAQAccordionMobile data={data.reabilitacaoEsteticaJson.facetas} />
            <FooterMobile data={data.globalJson.footer} />
          </>
        ) : (
          <>
            <Navbar />
            <BannerWithTextAndForm
              data={data.reabilitacaoEsteticaJson.facetas}
              TextColor="#000"
              pageName="Facetas Dentárias"
            />
            <ContextText
              facetas
              data={data.reabilitacaoEsteticaJson.facetas.contextText}
            />
            <MainSide data={data.reabilitacaoEsteticaJson.facetas.mainSide} />
            <CTA />
            <VantagesSmile
              data={data.reabilitacaoEsteticaJson.facetas.vantagesSmile}
            />
            <BoxSlides data={data.reabilitacaoEsteticaJson.facetas.boxSlide} />
            <FAQAccordion data={data.reabilitacaoEsteticaJson.facetas} />
            <Footer data={data.globalJson.footer} footerTop />
          </>
        )}
      </Layout>
    </>
  )
}

export default FacetasPage

export const Json = graphql`
  query facetas {
    reabilitacaoEsteticaJson {
      facetas {
        banner {
          title
          subtitle
          banner {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bannerMobile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        container {
          title
          txt
        }
        mainSide {
          left
          right
        }
        contextText {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          right {
            text {
              number
              txt
            }
            title
          }
        }
        vantagesSmile {
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          text {
            text
            title
          }
        }
        boxSlide {
          imgBefore {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgAfter {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        faq {
          question
          answer
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
