import React from "react"
import styled, { keyframes } from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"
import { StaticImage } from "gatsby-plugin-image"

import { useBreakpoint } from "gatsby-plugin-breakpoints"

import OT from "../../images/reabilitacao-estetica/popups-01.png"
import OTM from "../../images/reabilitacao-estetica/popups-02.png"

export const NewsletterModal = ({ setIsOpen }) => {
  return (
    <>
      <StyledModal>
        <div className="left">
          <div className={font.rSB + " close"} onClick={() => setIsOpen(false)}>
            X
          </div>
        </div>

        <div className=" container">
          <div>
            <Link to="/reabilitacao-estetica">
              {useBreakpoint().mobile ? (
                <img src={OTM} alt="carreira" className="image" />
              ) : (
                <img src={OT} alt="carreira" className="image" />
              )}
            </Link>
          </div>
        </div>
      </StyledModal>

      <StyledOverlay onClick={() => setIsOpen(false)} />
    </>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 70vw;
  border-radius: 0;
  opacity: 0;
  z-index: 111;
  overflow: hidden;
  animation: 0.3s ${fadeIn} ease forwards;
  padding: 1%;
  background-size: cover;

  @media only screen and (max-width: 700px) {
    padding: 5%;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .logo-btn {
      .logo {
        width: 50%;
      }
    }

    .close {
      font-size: 1.5rem;
      color: #fff;
      cursor: pointer;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-top: 3rem;
    overflow: hidden;

    h2 {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 3rem;
      font-size: 0.9rem;
    }

    .image {
      width: 100%;
      margin-bottom: 2%;
    }
  }

  @media screen and (max-width: 1000px) {
    max-width: 90%;
    width: 90%;
    min-height: 90vw;
    .close {
      font-size: 1.5rem;
      z-index: 999;
    }
  }
`

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
`
