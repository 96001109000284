import React from "react"
import styled from "styled-components"
import img1 from "../../images/reabilitacao-estetica/coroas/card-coroas.png"
import img2 from "../../images/reabilitacao-estetica/facetas/card-facetas-dentarias.png"
import img3 from "../../images/reabilitacao-estetica/branqueamentos/card-branqueamentos.png"
import arrowSeta from "../../images/reabilitacao-estetica/arrow-seta.png"

import font from "../../fonts/fonts.module.scss"

// Container principal que irá organizar os dois lados (esquerdo e direito)
const Container = styled.div`
  position: relative;
  margin-top: -5vw;

  .grid-container {
    grid-template-columns: repeat(14, 1fr);
  }
`
const ImageOption = styled.img`
  width: 100%;
`

const FirstSection = styled.div`
  position: relative;
  z-index: 1;
  grid-column: 1 / 5;
`
const SecondSection = styled.div`
  position: relative;
  z-index: 1;
  grid-column: 6 / 10;
`
const ThirdSection = styled.div`
  position: relative;
  z-index: 1;
  grid-column: 11 / -1;
`
const Subtitle = styled.h2`
  font-weight: bold;
  font-size: clamp(1.25rem, -0.2083rem + 1.9444vw, 2.125rem);
`
const ButtonArrow = styled.a`
  text-align: right;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`

// Estilo do subtítulo
const ContainerText = styled.div`
  background: #f0f0f0b0;
  padding: 60px 30px 30px 30px;
  position: relative;
  bottom: 30px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 261px;
`

const OptionServices = () => {
  return (
    <Container>
      <div className="grid-container">
        <FirstSection>
          <a href="/reabilitacao-estetica/coroas">
            <ImageOption src={img1} />
          </a>
          <ContainerText className={font.rR}>
            <Subtitle>Coroas</Subtitle>
            <p>
              Tem dentes danificados ou falta de dentes? As coroas sobre
              implante ou sobre prótese podem ser a solução ideal.
            </p>
            <ButtonArrow href="/reabilitacao-estetica/coroas">
              Saiba mais
              <img src={arrowSeta} />
            </ButtonArrow>
          </ContainerText>
        </FirstSection>
        <SecondSection>
          <a href="/reabilitacao-estetica/facetas-dentarias">
            <ImageOption src={img2} />
          </a>
          <ContainerText className={font.rR}>
            <Subtitle>Facetas Dentárias</Subtitle>
            <p>
              O seu sorriso tem pormenores que quer aperfeiçoar? As facetas
              dentárias, são uma solução simples que vai impulsionar a beleza do
              seu sorriso.
            </p>
            <ButtonArrow href="/reabilitacao-estetica/facetas-dentarias">
              Saiba mais
              <img src={arrowSeta} />
            </ButtonArrow>
          </ContainerText>
        </SecondSection>
        <ThirdSection>
          <a href="/reabilitacao-estetica/branqueamentos">
            <ImageOption src={img3} />
          </a>
          <ContainerText className={font.rR}>
            <Subtitle>Branqueamentos</Subtitle>
            <p>
              Gostava que o seu sorriso fosse mais branco? Em ambiente clínico,
              ou em ambulatório, o branqueamento vai dar-lhe o resultado que
              pretende!
            </p>
            <ButtonArrow href="/reabilitacao-estetica/branqueamentos">
              Saiba mais
              <img src={arrowSeta} />
            </ButtonArrow>
          </ContainerText>
        </ThirdSection>
      </div>
    </Container>
  )
}

export default OptionServices
