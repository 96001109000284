import React from "react"
import styled from "styled-components"
import smile from "../../images/reabilitacao-estetica/banner-main.png"
import font from "../../fonts/fonts.module.scss"

const Container = styled.div`
  position: relative;
  .smile-man {
    position: relative;
    width: 100%;
  }

  @media only screen and (min-width: 1921px) {
    .grid-container {
      margin: 0 auto;
      max-width: 1920px;
    }
  }

  .man-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const RightBox = styled.div`
  z-index: 2;
  grid-column: 6 / -1;
`

// Estilo do subtítulo
const SubTitle = styled.h2`
  color: #fff;
  text-align: right;
  font-size: clamp(3.125rem, 0rem + 4.1667vw, 5rem);

  b {
    font-family: "rB", sans-serif;
  }
`

const ContainerSmile = () => {
  return (
    <Container>
      <img src={smile} className="smile-man" />
      <div className="man-inside">
        <div className="grid-container">
          <RightBox>
            <SubTitle className={font.rR}>
              <b>
                Sorrisos
                <br /> bonitos e saudáveis
              </b>
              <br />
              estão, cada vez mais, <br />
              ao <b>alcance de todos!</b>
            </SubTitle>
          </RightBox>
        </div>
      </div>
    </Container>
  )
}

export default ContainerSmile
