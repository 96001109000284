import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"
import BannerWithTextAndForm from "../../components/reablitacao-estetica/BannerWithForm"
import ContainerSmile from "../../components/reablitacao-estetica/ContainerSmile"
import OptionServices from "../../components/reablitacao-estetica/OptionsServices"
import CTA from "../../components/reablitacao-estetica/CTA"
import BannerMobile from "../../components/reablitacao-estetica/BannerMobile"
import Context from "../../components/reablitacao-estetica/Context"
import ContainerSmileMobile from "../../components/reablitacao-estetica/ContainerSmileMobile"
import OptionServicesMobile from "../../components/reablitacao-estetica/OptionsServicesMobile"
import Form from "../../components/reablitacao-estetica/Form"
import NavbarReabilitacao from "../../components/reablitacao-estetica/navbarMobileReabilitacao"

const ReabilitacaoPage = ({ data }) => {
  return (
    <>
      <SEO
        titleTemplate="Reabilitação Estética"
        title="Carreira Dental Clinic"
        //noindex //ISSO É PRA NAO INDEXAR NO GOOGLE, SE VOLTAR A POR O OPENDAY ISSO NAO PODE ESTAR
      />
      <Layout home mobile={useBreakpoint().mobile}>
        {useBreakpoint().mobile ? (
          <>
            <NavbarReabilitacao />
            <BannerMobile
              data={data.reabilitacaoEsteticaJson.index}
              TextColor="#fff"
              flexPosition="flex-end"
              home
            />
            <Context data={data.reabilitacaoEsteticaJson.index} />
            <ContainerSmileMobile />
            <OptionServicesMobile />
            <Form
              data={data.reabilitacaoEsteticaJson.index}
              title
              pageName="Reabilitação estética"
            />
            <FooterMobile data={data.globalJson.footer} />
          </>
        ) : (
          <>
            <Navbar />
            <BannerWithTextAndForm
              data={data.reabilitacaoEsteticaJson.index}
              TextColor="#fff"
              pageName="Reabilitação estética"
            />
            <ContainerSmile />
            <OptionServices />
            <CTA margin="99px 0 0 0" />
            <Footer data={data.globalJson.footer} footerTop />
          </>
        )}
      </Layout>
    </>
  )
}

export default ReabilitacaoPage

export const Json = graphql`
  query reabilitacao {
    reabilitacaoEsteticaJson {
      index {
        banner {
          title
          subtitle
          banner {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bannerMobile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        container {
          title
          txt
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
